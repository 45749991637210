import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Biogeo from "/src/components/img/biogeo"
import ImgCon from "/src/components/img-con"
import BtnWrap from "/src/components/btn-wrap"
import SEO from "/src/components/seo"

export default ({ data }) => (
  <Layout>
    <SEO title="Biomy (globální společenstva)" />
    <h1>Biomy (globální společenstva)</h1>


    <p>Biomy (nebo tak&eacute; glob&aacute;ln&iacute; společenstva) jsou aplikac&iacute; konceptu <Link to="/biogeografie/spolecenstva/">prostorov&yacute;ch společenstev</Link> na glob&aacute;ln&iacute; &uacute;rovn&iacute;, ta je v&scaron;ak velmi problematick&aacute;. Vymezeny jsou na z&aacute;kladě struktury a fyziognomie vegetace, jednotliv&eacute; biomy tak maj&iacute; sv&eacute; charakteristick&eacute;, převažuj&iacute;c&iacute; <Link to="/ekologie-zivotni-prostredi/zivotni-vzrustove-formy/">životn&iacute; formy rostlin</Link>.</p>
    <p>Jednotliv&eacute; biomy jsou obvykle oblasti s charakteristick&yacute;m klimatem, půdami a vegetac&iacute; a v&iacute;cem&eacute;ně odpov&iacute;daj&iacute; klimatick&yacute;m p&aacute;sům.</p>
    <hr />
    <h2>Biomy podle různ&yacute;ch autorů</h2>
    <p>N&aacute;zory jednotliv&yacute;ch autorů na počet a vymezen&iacute; terestrick&yacute;ch biomů se v&yacute;razně li&scaron;&iacute;, m&aacute;me tak hned několik klasifikac&iacute; pevninsk&yacute;ch glob&aacute;ln&iacute;ch společenstev:</p>
    <ul>
    <li><strong>Lomolino et al. (2010)</strong> - celkem 12 biomů</li>
    <li><strong>Cox et al. (1999) </strong>- celkem 8 biomů</li>
    <li><strong>Strahler et al. (2011)</strong> - celkem 10 biomů</li>
    <li><strong>Walter (1985)</strong> - 9 tzv. zonobiomů</li>
    <li><strong>Světov&aacute; organizace pro ochranu př&iacute;rodu WWF</strong> - celkem 13 biomů</li>
    </ul>
    <hr />
    <h2>Generalizovan&aacute; klasifikace biomů</h2>
    <p>Biomy lze v z&aacute;kladu členit dle n&aacute;sleduj&iacute;c&iacute; generalizovan&eacute; klasifikace na:</p>
    <ul>
    <li><strong>tropick&yacute; de&scaron;tn&yacute; les</strong></li>
    <li><strong>stř&iacute;davě vlhk&yacute; (opadav&yacute;) tropick&yacute; les</strong></li>
    <li><strong>klimaxov&aacute; savana</strong></li>
    <li><strong>pou&scaron;ť</strong></li>
    <li><strong>st&aacute;lezelen&yacute; subtropick&yacute; les</strong></li>
    <li><strong>sklerofytn&iacute; (mediter&aacute;nn&iacute;) st&aacute;lezelen&yacute; les</strong></li>
    <li><strong>step</strong></li>
    <li><strong>opadav&yacute; listnat&yacute; les m&iacute;rn&eacute;ho p&aacute;su</strong></li>
    <li><strong>tajga</strong></li>
    <li><strong>tundra</strong></li>
    <li><strong>pol&aacute;rn&iacute; pustina</strong></li>
    </ul>
    <h3>Tropick&yacute; de&scaron;tn&yacute; les</h3>
    <p>Tropick&yacute; de&scaron;tn&yacute; les m&aacute; trvale humidn&iacute; až hyperhumidn&iacute; klima, s ročn&iacute;mi &uacute;hrny 2000-3000&nbsp;mm. Teplota je cel&yacute; rok st&aacute;l&aacute; kolem 25-28&nbsp;&deg;C, denn&iacute; amplituda teploty dosahuje maxim&aacute;lně 6-11&nbsp;&deg;C. St&aacute;l&aacute; je i půdn&iacute; teplota.</p>
    <p>Nach&aacute;z&iacute; se zde star&aacute; a hluboce zvětral&eacute; půdy s vysok&yacute;m pod&iacute;lem oxidů železa, hlin&iacute;ku a kaolinitu. Je zde m&aacute;lo živin v z&aacute;sobě, odumřel&aacute; biomasa se rychle rozkl&aacute;d&aacute; a uvolněn&eacute; živiny jsou ihned využ&iacute;v&aacute;ny rostlinami.</p>
    <p>Vegetaci tvoř&iacute; vždyzelen&yacute; les, s nepravideln&yacute;mi cykly kveten&iacute;, růstu a v&yacute;měny listů. Diverzita je vysok&aacute;, zejm&eacute;na u stromů, a je realizov&aacute;na hlavně ve vy&scaron;&scaron;&iacute;ch patrech. Na zemi je pak relativně m&aacute;lo druhů. V de&scaron;tn&yacute;ch lesech je pak v&yacute;razn&aacute; patrovitost (m&aacute;lo světla a vysok&aacute; vzdu&scaron;n&aacute; vlhkost v doln&iacute;ch patrech), velk&aacute; diferenciace nik a obrovsk&aacute; rozmanitost potravn&iacute;ch vazeb.</p>
    <p>Ze <Link to="/ekologie-zivotni-prostredi/zivotni-vzrustove-formy/">životn&iacute;ch forem</Link> zde <strong>převažuj&iacute; fanerofyty</strong>, t&eacute;měř chyběj&iacute; kryptofyty a terofyty. Je zde velk&eacute; zastoupen&iacute; epifytů, poloepifytů a li&aacute;n.</p>
    <h3>Stř&iacute;davě vlhk&eacute; (opadav&eacute;) tropick&eacute; lesy</h3>
    <p>Stř&iacute;davě vlhk&eacute; a opadav&eacute; tropick&eacute; lesy jsou typick&eacute; stř&iacute;d&aacute;n&iacute;m obdob&iacute; sucha (zima) a de&scaron;ťů, monzunů (l&eacute;to). Průměrn&eacute; měs&iacute;čn&iacute; teploty se pohybuj&iacute; mezi 20 a 28&nbsp;&deg;C. Ročn&iacute; &uacute;hrny sr&aacute;žek čin&iacute; 900 až 2300&nbsp;mm, přičemž naprost&aacute; vět&scaron;ina z nich spadne v l&eacute;tě.</p>
    <p>U vegetace se objevuj&iacute; adaptace na sucho, konkr&eacute;tně shazov&aacute;n&iacute; listů. D&iacute;ky vět&scaron;&iacute;mu prosvětlen&iacute; v podrostu je zde vy&scaron;&scaron;&iacute; diverzita bylin, lesy jsou m&eacute;ně strukturovan&eacute;.</p>
    <h3>Klimaxov&aacute; savana</h3>
    <p>V klimaxov&eacute; savaně panuje humidně-aridn&iacute; klima se stř&iacute;d&aacute;n&iacute;m obdob&iacute; sucha a de&scaron;ťů, přičemž v obdob&iacute; sucha se typicky objevuj&iacute; pož&aacute;ry. Teploty jsou každ&yacute; měs&iacute;c vy&scaron;&scaron;&iacute; než 18&nbsp;&deg;C, průměrn&aacute; měs&iacute;čn&iacute; teplota dosahuje 20-28&nbsp;&deg;C. Ročn&iacute; &uacute;hrny sr&aacute;žek se pohybuj&iacute; v rozmez&iacute; 700 až 1500&nbsp;mm, při čemž alespoň jeden měs&iacute;c v roce jsou niž&scaron;&iacute; než 60&nbsp;mm.</p>
    <p>Půdy v savaně jsou star&eacute; a hluboce zvětral&eacute;, čast&eacute; je sr&aacute;žen&iacute; železa (lateritizace) a tvrdnut&iacute; půd (alkrety, kalkrety aj.). Půdy maj&iacute; omezenou z&aacute;sobu živin. V savan&aacute;ch je poměrně vysok&eacute; riziko desertifikace.</p>
    <p>Přev&aacute;žn&aacute; č&aacute;st dne&scaron;n&iacute; vegetace savan je antropogenn&iacute;ho původu. D&iacute;ky sez&oacute;nn&iacute; ariditě a pož&aacute;rům do&scaron;lo k selekci trav na &uacute;kor dřevin. Běžně se zde vyskytuj&iacute; <Link to="/ekologie-zivotni-prostredi/dalsi-ekologicke-faktory/">pyrofyty</Link>. Často dominuj&iacute; vysok&eacute; tropick&eacute; tr&aacute;vy a <Link to="/biogeografie/ekologicka/abioticke-faktory/">C4 rostliny</Link>. Vegetace savan může m&iacute;t rozličnou strukturu, rozli&scaron;ujeme např. kr&aacute;tkost&eacute;belnat&eacute;, dlouhost&eacute;belnat&eacute;, křovit&eacute; či stromovit&eacute; savany a savanov&eacute; lesy.</p>
    <p>V savan&aacute;ch jsou převažuj&iacute;c&iacute; životn&iacute; formou rostlin <strong>hemikryptofyty</strong> a <strong>terofyty</strong>.</p>
    <h3>Pou&scaron;tě a polopou&scaron;tě</h3>
    <p>Pou&scaron;tě a polopou&scaron;tě je biom typick&yacute; extr&eacute;mn&iacute;m, prakticky celoročn&iacute;m suchem. Nach&aacute;z&iacute; se nejčastěji mezi 15 a 30&deg; z.&scaron;. či ve sr&aacute;žkov&eacute;m st&iacute;nu. Pou&scaron;tě lze dělit na:</p>
    <ul>
    <li><strong>horsk&eacute; pou&scaron;tě</strong> (denn&iacute; amplituda teplot přes 50&deg;&nbsp;C)</li>
    <li><strong>studen&eacute; pou&scaron;tě</strong><strong>m&iacute;rn&eacute;ho p&aacute;su</strong> (velmi tuh&eacute; zimy, např. pou&scaron;ť Gobi)</li>
    </ul>
    <p>Půdy v pou&scaron;t&iacute;ch podl&eacute;haj&iacute; siln&eacute;mu zvětr&aacute;v&aacute;n&iacute; a erozi, je zde vysok&aacute; koncentrace sol&iacute; a velmi n&iacute;zk&yacute; obsah dus&iacute;ku a jin&yacute;ch živin. Dekompozice je v pou&scaron;t&iacute;ch a polopou&scaron;t&iacute;ch pomal&aacute;.</p>
    <p>Ve vegetaci je vysok&eacute; zastoupen&iacute; sukulentů, kter&eacute; jsou schopny zadržovat vodu v pletivech a jsou charakteristick&eacute; sv&yacute;mi ostny. Četn&eacute; zastoupen&eacute; maj&iacute; v pou&scaron;t&iacute;ch <Link to="/biogeografie/ekologicka/abioticke-faktory/">CAM rostliny</Link>.</p>
    <p>Z životn&iacute;ch forem se v tomto biomu nejčastěji vyskytuj&iacute; <strong>terofyty</strong> a <strong>hemikryptofyty</strong> (tr&aacute;vy o střice) či <strong>chamaefyty</strong> (keř&iacute;čky).</p>
    <h3>St&aacute;lezelen&eacute; subtropick&eacute; lesy</h3>
    <p>Klima st&aacute;lezelen&yacute;ch subtropick&yacute;ch lesů je typick&eacute; dostatkem sr&aacute;žek po cel&yacute; rok a m&iacute;rn&yacute;mi, kr&aacute;tk&yacute;mi zimami. Ročn&iacute; &uacute;hrn sr&aacute;žek je asi 1200-1700&nbsp;mm, průměrn&eacute; měs&iacute;čn&iacute; teploty jsou 8-22&nbsp;&deg;C. Průměrn&aacute; ročn&iacute; teplota je pak vy&scaron;&scaron;&iacute; než 13&nbsp;&deg;C.</p>
    <p>Vegetace st&aacute;lezelen&yacute;ch subtropick&yacute;ch lesů lze rozdělit do dvou z&aacute;kladn&iacute;ch skupin:</p>
    <ul>
    <li><strong>subtropick&yacute; st&aacute;lezelen&yacute; listnat&yacute; les</strong> (vavř&iacute;ny, magn&oacute;lie, cykasy, bambusy, blahovičn&iacute;ky aj.)</li>
    <li><strong>subtropick&yacute; jehličnat&yacute; les</strong> (sekvoje v Kalifornii, <em>Podocarpus</em> a <em>Dacrydium </em>na Nov&eacute;m Z&eacute;landu)</li>
    </ul>
    <h3>Sklerofytn&iacute; (mediter&aacute;nn&iacute;) vegetace</h3>
    <p>Biom sklerofytn&iacute; (či mediter&aacute;nn&iacute;) vegetace se nach&aacute;z&iacute; v oblastech s m&iacute;rnou a vlhkou zimou a such&yacute;m hork&yacute;m l&eacute;tem. Průměrn&aacute; ročn&iacute; teplota čin&iacute; 21-27&nbsp;&deg;C, při čemž v zimě se průměrně pohybuje mezi 5 až 12&nbsp;&deg;C a v l&eacute;tě dosahuje až 35&nbsp;&deg;C. Mrazy se nevyskytuj&iacute; či jen zř&iacute;dka.</p>
    <p>Půdy jsou zde mělk&eacute;, za sucha tvrdnou a jdou chud&eacute; na př&iacute;stupn&yacute; dus&iacute;k a fosfor. Nach&aacute;z&iacute; se zde terra rossa, půdy v&aacute;pnit&eacute;, kamenit&eacute; a j&iacute;lovit&eacute;, s rezav&yacute;m zbarven&iacute;m a vysok&yacute;m obsahem Fe2O3.</p>
    <p>Vegetace m&aacute; vysokou diverzitu. Běžn&aacute; je př&iacute;tomnost adaptac&iacute; na letn&iacute; sucho, a to zejm&eacute;na sez&oacute;nn&iacute;ho dimorfizmu listů (mal&eacute; letn&iacute; listy), shazov&aacute;n&iacute; listů či sklerofytn&iacute; adaptace. Je př&iacute;tomen v&yacute;razn&yacute; lidsk&yacute; vliv, nejv&iacute;ce pak ve středomoř&iacute;, kde vlivem vypalov&aacute;n&iacute;, pastevectv&iacute; a k&aacute;cen&iacute; roste druhotn&aacute; vegetace. V důsledku změn hospodařen&iacute; v&scaron;ak zde opět doch&aacute;z&iacute; k opětovn&eacute; sukcesi směrem ku lesn&iacute; vegetaci.</p>
    <p>V biomu sklerofytn&iacute; vegetace jsou zastoupeny v&scaron;echny <Link to="/ekologie-zivotni-prostredi/zivotni-vzrustove-formy/">životn&iacute; formy rostlin</Link>. Region&aacute;ln&iacute; n&aacute;zvy současn&eacute; vegetace se li&scaron;&iacute;:</p>
    <ul>
    <li>macchie, garrigue (středomoř&iacute;)</li>
    <li>fynbos, veld (Jižn&iacute; Amerika)</li>
    <li>mallee (Austr&aacute;lie)</li>
    <li>chaparral (Kalifornie)</li>
    <li>matorral (Chile)</li>
    </ul>
    <h3>Stepi</h3>
    <p>Stepi se rozkl&aacute;daj&iacute; v oblastech s celoročně n&iacute;zk&yacute;mi sr&aacute;žkami, jejichž ročn&iacute; &uacute;hrn b&yacute;v&aacute; průměrně 300-600&nbsp;mm. Ty jsou niž&scaron;&iacute; než potenci&aacute;ln&iacute; v&yacute;par a často vypad&aacute;vaj&iacute; ve formě n&aacute;hl&yacute;ch lij&aacute;ků. Průměrn&eacute; teploty se pohybuj&iacute; mezi 5-15&nbsp;&deg;C, pro zimn&iacute; obdob&iacute; jsou typick&eacute; siln&eacute; mrazy.</p>
    <p>Ve step&iacute;ch se nejčastěji vyskytuj&iacute; černozemě s mohutn&yacute;m humusov&yacute;m horizontem, půdy jsou fertiln&iacute; a bazick&eacute;. K humifikaci doch&aacute;z&iacute; snadno, mineralizace je zpomalena nedostatkem vody. V aridněj&scaron;&iacute;ch oblastech se nach&aacute;z&iacute; tzv. solončaky (označen&iacute; pro slaniska).</p>
    <p>Stepi jsou př&iacute;značn&eacute; dominanc&iacute; trav, v letn&iacute;m obdob&iacute; jsou čast&eacute; pož&aacute;ry. Převažuj&iacute; <strong><Link to="/ekologie-zivotni-prostredi/zivotni-vzrustove-formy/">hemikryptofyty</Link></strong>, vysok&eacute; je rovněž zastoupen&iacute; <strong><Link to="/ekologie-zivotni-prostredi/zivotni-vzrustove-formy/">geofytů</Link></strong>. Pro stepi se použ&iacute;vaj&iacute; různ&aacute; region&aacute;ln&iacute; označen&iacute;:</p>
    <ul>
    <li><strong>stepi</strong> (Eurasie)</li>
    <li><strong>celiny</strong> (středn&iacute; Asie)</li>
    <li><strong>pusty</strong> (Maďarsko, antropogenně podm&iacute;něn&eacute;)</li>
    <li><strong>pr&eacute;rie</strong> (S. Amerika)</li>
    <li><strong>pampy</strong> (Argentina)</li>
    <li><strong>grassveld</strong> (n&aacute;horn&iacute; plo&scaron;iny v J. Africe)</li>
    </ul>
    <h3>Opadav&yacute; les m&iacute;rn&eacute;ho p&aacute;smu</h3>
    <p>Biom opadav&yacute;ch lesů m&iacute;rn&eacute;ho p&aacute;smu je z hlediska klimatu charakterizov&aacute;n dostatečn&yacute;m množstv&iacute;m sr&aacute;žek během vegetačn&iacute;ho obdob&iacute;, kter&eacute; trv&aacute; 4 až 6 měs&iacute;ců (kdy je v průměru v&iacute;ce než 10&nbsp;&deg;C). Ročn&iacute; &uacute;hrn sr&aacute;žek je 450 až 1800&nbsp;mm s maximem vět&scaron;inou v letn&iacute;m obdob&iacute;. Typick&eacute; je stř&iacute;d&aacute;n&iacute; tepl&yacute;ch l&eacute;t a chladn&yacute;ch zim s mrazy s průměrnou ročn&iacute; teplotou 5-16&nbsp;&deg;C.</p>
    <p>Nejčastěj&scaron;&iacute;m půdn&iacute;m typem jsou kambizemě, hněd&eacute; lesn&iacute; půdy s humusov&yacute;m horizontem a horizontem vnitropůdn&iacute;ho zvětr&aacute;v&aacute;n&iacute;, kter&eacute; jsou vět&scaron;inou m&iacute;rně kysel&eacute;. Projevuje se zde vliv listov&eacute;ho opadu na pH a &uacute;živnost půdy.</p>
    <p>Diverzita je realizov&aacute;na zejm&eacute;na v podrostu lesů. Př&iacute;tomn&aacute; je opadavost, adaptace na such&eacute; obdob&iacute; v zimě, kdy voda zamrzne. Typick&eacute; jsou druhy rodu buk a pabuk. V oblastech s vy&scaron;&scaron;&iacute;mi &uacute;hrny sr&aacute;žek se nach&aacute;z&iacute; de&scaron;tn&eacute; lesy m&iacute;rn&eacute;ho p&aacute;sma, v nichž pak rostou předev&scaron;&iacute;m sekvoje, tsugy či borovice a smrky.</p>
    <p>Převažuj&iacute; <strong>hemikryptofyty</strong> a <strong>fanerofyty</strong>, čast&eacute; jsou i <strong>geofyty</strong>, zejm&eacute;na využ&iacute;vaj&iacute;c&iacute; časov&eacute; niky (např. jarn&iacute; efem&eacute;ry).</p>
    <h3>Tajga</h3>
    <p>Tajga je biom klimaticky definovan&yacute; dlouhou a chladnou zimou a kr&aacute;tk&yacute;m, vět&scaron;inou jen m&iacute;rně tepl&yacute;m l&eacute;tem. Průměrn&aacute; ročn&iacute; teplota se pohybuje v rozmez&iacute; -5&nbsp;až&nbsp;3&nbsp;&deg;C. Severn&iacute; hranici tajgy určuje izoterma průměrn&yacute;ch teplot nejteplej&scaron;&iacute;ho měs&iacute;ce s hodnotou 10&nbsp;&deg;C. Ročn&iacute; &uacute;hrn sr&aacute;žek čin&iacute; 350-750&nbsp;mm s maximem v l&eacute;tě, evapotranspirace je typicky n&iacute;zk&aacute;.</p>
    <p>V tajg&aacute;ch se nach&aacute;z&iacute; permafrost, podzoly, rankery i zra&scaron;eliněl&eacute; půdy. Pomal&aacute; dekompozice je př&iacute;značn&aacute; pro tento biom.</p>
    <p>Vegetace tajgy je charakteristick&aacute; n&iacute;zkou druhovou diverzitou stromů, ve společenstvech dominuj&iacute; jehličnany (smrk, modř&iacute;n a borovice). Při jižn&iacute; hranici tajgy se mohou vyskytovat hemibore&aacute;ln&iacute; lesy, ve kter&yacute;ch je naopak v&yacute;razn&yacute; pod&iacute;l listnat&yacute;ch stromů. Rozli&scaron;ujeme dva z&aacute;kladn&iacute; podtypy tajgy:</p>
    <ul>
    <li><strong>tmav&aacute; smrkov&aacute; tajga</strong> (nedostatek světla v podrostu)</li>
    <li><strong>světl&aacute; tajga</strong> (v&iacute;ce světla v podrostu, v&iacute;ce borovic a modř&iacute;nů)</li>
    </ul>
    <p>Ze životn&iacute;ch forem rostlin převažuj&iacute; <strong>hemikryptofyty</strong>.</p>
    <h3>Tundra</h3>
    <p>Tundra je biom s průměrnou ročn&iacute; teplotou pod 0&nbsp;&deg;C, průměrn&aacute; teplota nejteplej&scaron;&iacute;ho měs&iacute;ce nepřesahuje 10&nbsp;&deg;C. Množstv&iacute; ročn&iacute;ch sr&aacute;žek se pohybuje okolo 250 až 500&nbsp;mm.</p>
    <p>Převažuje permafrost, ra&scaron;elinn&eacute; půdy, kryosoly a polygon&aacute;tn&iacute; půdy. Charakteristick&aacute; je velmi pomal&aacute; dekompozice či vyluhov&aacute;n&iacute; b&aacute;z&iacute; z horn&iacute;ch vrstev půd, vlivem čehož roste pH půdy s jej&iacute; hloubkou.</p>
    <p>Ve vegetaci tundry dominuj&iacute; vytrval&eacute; rostliny a zakrsl&eacute; keř&iacute;čky rostouc&iacute; při zemi. Keře, stromky a jin&eacute; lesn&iacute; druhy rostou v depres&iacute;ch, kde je v zimě cel&eacute; překryje sněhov&aacute; pokr&yacute;vka a jsou tak chr&aacute;něny před mrazem. Adaptac&iacute; na chlad je růst trav a ostřic v trsech a růst &scaron;irolist&yacute;ch bylin v pol&scaron;t&aacute;ř&iacute;ch nebo hust&yacute;ch růžic&iacute;ch.</p>
    <p>Převažuj&iacute;c&iacute; životn&iacute; formou rostlin jsou <strong>hemikryptofyty</strong>.</p>

    <hr />
    <ImgCon><Biogeo /><div>
    <h2>Studijn&iacute; materi&aacute;ly Biogeografie</h2>
    <p>T&eacute;ma <strong>Biomy (globální společenstva)</strong> je souč&aacute;st&iacute; <Link to="/biogeografie/">studijn&iacute;ch materi&aacute;lů Biogeografie</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte předch&aacute;zej&iacute;c&iacute; (2.&nbsp;tlač&iacute;tko) či n&aacute;sleduj&iacute;c&iacute; (3. tlač&iacute;tko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/biogeografie/"><button>Rozcestník biogeografie</button></Link>
    <Link to="/biogeografie/spolecenstva/"><button className="inv">&larr; Společenstva</button></Link>
    <Link to="/biogeografie/spolecenstva/vegetace-evropy/"><button className="inv">Vegetace Evropy &rarr;</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />
  </Layout>
)
